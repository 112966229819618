export default defineAppConfig({
  app: {
    logo: 'https://shoebill.sgp1.cdn.digitaloceanspaces.com/img/clinic-logo/shoebill-co-traveller-small.svg',
    ogImg:
      // 'https://shoebill.sgp1.cdn.digitaloceanspaces.com/img/about/ogImg.jpg',
      '/img/about-og.jpg',
  },
  // landing: {
  //   heroImg:
  //     'https://shoebill.sgp1.cdn.digitaloceanspaces.com/uploads/f8c6f44a5bdcae7c2d4476acb860ee90.jpg',
  // },
  socialLinks: [
    {
      icon: 'i-tabler-brand-whatsapp',
      url: 'https://wa.me/message/YUUGYZK7MRUSK1',
      label: 'WhatsApp',
    },
    {
      icon: 'i-tabler-mail',
      url: 'mailto:service@shoebillhealth.com',
      label: 'Email',
    },
    {
      icon: 'i-tabler-brand-linkedin',
      url: 'https://www.linkedin.com/company/31332282',
      label: 'LinkedIn',
    },
    {
      icon: 'i-tabler-brand-wechat',
      url: 'https://shoebill.sgp1.cdn.digitaloceanspaces.com/img/wechat.webp',
      label: 'WeChat',
    },
    {
      icon: 'i-tabler-brand-facebook',
      url: 'https://www.facebook.com/shoebillhealth',
      label: 'Facebook',
    },
    {
      icon: 'i-tabler-brand-instagram',
      url: 'https://instagram.com/shoebillhealth',
      label: 'Instagram',
    },
  ],
  ui: {
    primary: 'orange',
    gray: 'neutral',
    icons: {
      dark: 'i-tabler-moon',
      light: 'i-tabler-sun',
      system: 'i-tabler-device-mobile',
      search: 'i-tabler-search',
      external: 'i-tabler-external-link',
      chevron: 'i-tabler-chevron-right',
      hash: 'i-tabler-hash',
      menu: 'i-tabler-menu-2',
      close: 'i-tabler-x',
      check: 'i-tabler-check',
    },
    page: {
      header: {
        wrapper: 'border-0',
      },
    },

    header: {
      wrapper: 'border-none',
      container: 'sm:px-4',
      links: {
        wrapper: 'items-center gap-x-4 hidden lg:flex',
        base: 'text-sm/6 font-semibold flex items-center gap-0',
        trailingIcon: {
          name: 'i-heroicons-chevron-down-20-solid',
          base: 'w-5 h-5 transform transition-transform duration-200 flex-shrink-0', // chevron down icon
          active: 'rotate-180',
        },
        default: {
          popover: {
            openDelay: 200,
            ui: {
              width: 'max-w-[28rem]',
            },
          },
        },
      },
      popover: {
        // overlay: {
        //   base: 'top-100 inset-0 transition-opacity z-50',
        // },
        links: {
          base: 'gap-3',
          icon: {
            base: 'w-8 h-8',
          },
        },
      },
      button: {
        icon: {
          open: 'i-tabler-menu-2',
          close: 'i-tabler-x',
        },
      },
    },

    card: {
      ring: 'ring-0  ',
      // divider: 'divide-y divide-red-50 dark:divide-neutral-700',
    },

    landing: {
      section: {
        wrapper: 'py-16 sm:py-24',
        description:
          'leading-7 mx-auto max-w-2xl text-md md:text-lg text-zinc-500 dark:text-zinc-400',
      },
    },
    // for page breadcrumb
    breadcrumb: {
      wrapper: 'pt-4 sm:pt-6 lg:pt-10',
      ol: 'gap-x-1 md:gap-x-3',
      li: 'gap-x-1 md:gap-x-3',
      default: {
        divider: 'i-tabler-chevron-right',
      },
    },

    pricing: {
      grid: {
        wrapper:
          'flex flex-col lg:grid lg:grid-cols-2 w-full justify-center items-center gap-10',
      },

      card: {
        title: 'text-wrap',
        // divider: 'divide-y divide-red-300 dark:divide-neutral-700',
        // body: {
        //   padding: 'p-2 lg:p-4 xl:p-8',
        // },
      },
    },
    blog: {
      post: {
        image: {
          wrapper:
            'shadow-md ring-0 ring-primary-200 dark:ring-neutral-800 relative overflow-hidden aspect-[16/9] w-full rounded-lg pointer-events-none',
          base: 'object-cover object-top w-full h-full transform transition-transform duration-200 group-hover:scale-105',
        },
      },
    },
    accordion: {
      default: {
        openIcon: 'i-tabler-chevron-down',
      },
    },
  },
  button: {
    default: {
      loadingIcon: 'i-tabler-loader',
    },
  },

  // form input styles
  input: {
    wrapper: 'mt-2',
    default: {
      size: 'xl',
      color: 'white',
      loadingIcon: 'i-tabler-loader',
    },
  },
  select: {
    wrapper: 'mt-2',
    default: {
      size: 'xl',
      color: 'white',
      loadingIcon: 'i-tabler-loader',
      trailingIcon: 'i-tabler-chevron-down',
    },
  },
  selectMenu: {
    default: {
      selectedIcon: 'i-tabler-check',
    },
    textarea: {
      wrapper: 'mt-2',
      default: {
        size: 'xl',
        color: 'white',
      },
    },
  },
})
